<template>
  <div class="bg">
    <div class="top mb8">
      <img
        src="https://oimagec8.ydstatic.com/image?id=-875241627009222359&product=adpublish&format=JPEG&w=520&h=347"
        alt=""
      />
    </div>
    <div class="">
      <van-form @submit="onSubmit">
        <van-field
          v-model="form.nickname"
          name="昵称"
          label="昵称"
          placeholder="昵称"
          :rules="[{ required: true, message: '请填写昵称' }]"
        />
        <van-field name="radio" label="性别">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio name="0">保密</van-radio>
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.introduce"
          rows="6"
          autosize
          label="自我介绍"
          type="textarea"
          maxlength="100"
          placeholder="请输入自我介绍"
          show-word-limit
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"> 提交 </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
export default {
  data() {
    return {
      form: {
        nickname: "",
        cover: "",
        sex: "",
        introduce: "",
      },
    };
  },
  methods: {
    onSubmit() {},
    showDialog() {
      Dialog.alert({
        title: "标题",
        message: "弹窗内容",
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  .top {
    background: #fff;
    padding: 16px 16px 48px 16px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
}
</style>
